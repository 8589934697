import React, { useEffect, useRef } from "react";
import "./App.css";

function App() {
  const canvas = useRef(null);

  useEffect(() => {
    const c = canvas.current;
    const x = c.getContext("2d");

    c.width = window.innerWidth;
    c.height = window.innerHeight;

    let S = Math.sin;
    let C = Math.cos;
    let T = Math.tan;
    function R(r, g, b, a) {
      a = a === undefined ? 1 : a;
      return "rgba(" + (r | 0) + "," + (g | 0) + "," + (b | 0) + "," + a + ")";
    }

    let time = 0;
    let frame = 0;
    let nextFrameMs = 0;
    let FPS = 60;
    let playing = true;

    function dweet(t) {
      let i, a, j, k;
      for (
        i = 2e3;
        t < 9 && i--;
        x.fillRect(
          i,
          (T + 3) * t * 40,
          1,
          2e3,
          (x.fillStyle = R((a = 255 - t * t * 4), a, a))
        )
      )
        for (
          T = 0, j = 9;
          j--;
          T = T / 2 + C(((i - 960 * S(t * k)) * k) / t / 99)
        )
          k = 2 ** j;
    }

    function pauseDemo() {
      playing = false;
    }

    function loop(frame_time) {
      if (playing) {
        requestAnimationFrame(loop);
        const epsilon = 1.5; // Acounts for different timestamp resolution and slight jitter
        if (frame_time < nextFrameMs - epsilon) {
          return; // Skip this cycle as we are animating too quickly.
        }
        nextFrameMs = Math.max(nextFrameMs + 1000 / FPS, frame_time);
      }

      time = frame / FPS;
      if ((time * FPS) | (0 === frame - 1)) {
        time += 0.01;
      }
      frame++;

      try {
        if (time > 10) pauseDemo();
        dweet(time);
      } catch (e) {
        throw e;
      }
    }

    loop(0.0);
  });

  return (
    <div className="App">
      <header className="App-header">
        <p>Kimballers</p>
      </header>
      <canvas id="canvas" ref={canvas}></canvas>
    </div>
  );
}

export default App;
